import { get_feature_flag_property } from './feature_flags';
import { checkValueAgainstList } from './main';

export function configureTempPrivilegeTopNav(button) {
  const canUserManageAccounts = checkValueAgainstList('manage_accounts', localStorage.permissions || []);
  get_feature_flag_property('temporary-privilege-escalation.enabled').then(isEnabled => {
    if (isEnabled && canUserManageAccounts) {
      button.classList.remove('d-none');
    }
  });
}
